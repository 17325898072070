import React from 'react';

const Hero = () => {
  return (
    <section className="hero background is-fullheight" >
      <div className="hero-body">
        <div className="container center">
          <div className="content">
            <h1 className="is-size-1 has-text-white">nerdshop</h1>
            <h3 className="is-size-3 has-text-white">web & mobile app design</h3>
          </div>
        </div>
      </div>
    </section >
  );
};

export default Hero;
